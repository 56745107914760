import Vue from 'vue'

// 全局公用方法

// 格式化时间日期
function dataFormet(originVal) {
  const dt = new Date(originVal)
  const y = dt.getFullYear()//年份
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')//月
  const d = (dt.getDate() + '').padStart(2, '0')//天数

  return `${y} 年 ${m} 月 ${d} 日`
}

function nowDataFormet() {
  const now_millisecond = Date.now()
  return dataFormet(now_millisecond)
}

Vue.prototype.$dataFormet = dataFormet
Vue.prototype.$nowDataFormet = nowDataFormet
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: { name: 'Login' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      index: 1
    }
  },
  {
    path: '/home',
    name: 'Home',
    redirect: '/memberInfo',
    component: () => import('../views/Home.vue'),
    meta: {
      index: 2
    },
    children: [
      { path: '/index', name: 'Index', component: () => import('../components/Index.vue') },
      { path: '/memberInfo', name: 'MemberInfo', component: () => import('../components/MerberInfo.vue') },
      { path: '/dashboard', name: 'Dashboard', component: () => import('../components/Dashboard.vue') },
      { path: '/record', name: 'Record', component: () => import('../components/Record.vue') },
    ]
  },
  {
    path: '/401',
    name: '401',
    component: () => import('../views/401.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})


// 路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.name === 'Login') return next()
  const token = window.sessionStorage.getItem('token')
  if (!token || !token.length) return next({ name: 'Login' })
  next()
})

// 挂在全局后退方法
Vue.prototype.$GOBACK = () => router.go(-1)
export default router

// 挂在全局跳转方法
Vue.prototype.$PUSH = (name) => router.push({ name })
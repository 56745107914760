import Vue from 'vue'
import axios from 'axios'
import router from '../router';

import { Loading, Message } from 'element-ui';
import { baseUrl } from './api.init'
let loadingObj;
let requestCount = 0
axios.defaults.baseURL = baseUrl

axios.defaults.timeout = 10000

axios.interceptors.request.use(config => {
  // requestCount++
  if (config.url.includes('/member/')) config.headers.token = window.sessionStorage.getItem("token")
  loadingObj = Loading.service({
    lock: true,
    text: '玩命加载中!!!',
    background: 'rgba(0, 0, 0, 0.7)',
    spinner: 'el-icon-loading',
  })
  return config
})

axios.interceptors.response.use(response => {
  // requestCount--
  // if (!requestCount) loadingObj.close()
  loadingObj.close()
  return response
}, err => {
  console.log('错误响应', err.response)
  try {
    loadingObj.close()
    if (err && err.response.status) {
      switch (err.response.status) {
        case 400:
          Message({ type: 'error', message: '错误请求' })
          break;
        case 401:
          Message({ type: 'error', message: '未授权' })
          router.push({ name: 'Login' })
          // $PUSH('Login')
          break;
        case 403:
          Message({ type: 'error', message: '禁止' })
          break;
        case 404:
          Message({ type: 'error', message: '未找到' })
          break;
        case 405:
          Message({ type: 'error', message: '方法禁用' })
          break;
        case 406:
          Message({ type: 'error', message: '不接受' })
          break;
        case 407:
          Message({ type: 'error', message: '需要代理授权' })
          break;
        case 408:
          Message({ type: 'error', message: '请求超时' })
          break;
        case 409:
          Message({ type: 'error', message: '冲突' })
          break;
        case 500:
          Message({ type: 'error', message: '服务器内部错误' })
          break;
        case 501:
          Message({ type: 'error', message: '尚未实施' })
          break;
        case 502:
          Message({ type: 'error', message: '错误网关' })
          break;
        case 503:
          Message({ type: 'error', message: '服务不可用' })
          break;
        case 504:
          Message({ type: 'error', message: '网关超时' })
          break;
        case 505:
          Message({ type: 'error', message: 'HTTP 版本不受支持' })
          break;
        default:
          Message({ type: 'error', message: '未知错误' })
          break;
      }
    }
  } catch (error) {
    Message({ type: 'warning', message: '请求超时' })
    console.log(error)
  }
  return Promise.reject(err)
})

Vue.prototype.$axios = axios

Vue.prototype.$GET = async (url, params) => {
  return await axios.get(url, { params })
}

Vue.prototype.$POST = async (url, params) => {
  return await axios.post(url, params)
}

Vue.prototype.$PUT = async (url, params) => {
  return await axios.put(url, params)
}

Vue.prototype.$DELETE = async (url, params) => {
  return await axios.delete(url, params)
}
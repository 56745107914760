<template>
  <div id="app">
    <!-- <button @click="addMember">新增会员</button>
    <button @click="autonAddMember">自动新增 {{ count }} 次</button> -->

    <!-- <transition name="slide-fade">
      <router-view />
    </transition> -->
    <transition name="el-zoom-in-center">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      // 视频地址
      videoSrc: '',
      count: 0,
      memberList: []
    }
  },
  created() {
    // this.getMemberList()
  },
  methods: {
    // 获取会员列表
    async getMemberList() {
      const { data: res } = await this.$axios.get('http://127.0.0.1:3000/memberlist')
      // console.log('会员列表', res)
      const { code, message, list } = res
      if (code !== 200 && message !== '成功') return
      this.memberList = list
      // console.log(this.memberList)
    },
    // 新增会员
    async addMember(item) {
      const { userName, createTime, phoneNum, money, balance, consumeList } = item
      const params = {
        userName,
        createTime,
        phoneNum,
        type: 1,
        money,
        balance: money - this.balance(consumeList),
        consumeList
      }

      await this.$axios.post('http://127.0.0.1:3000/member/addMember', params)
    },
    autonAddMember() {
      setInterval(async () => {
        if (this.count - 1 >= this.memberList.length) return this.count = '写入结束'
        await this.addMember(this.memberList[this.count])
        this.count++
      }, 100)

    }
  },
  computed: {
    balance() {
      return (list) => {
        let total = 0
        list.forEach(item => total += item.consumeMoney)
        return total
      }
    }
  },

  watch: {//使用watch 监听$router的变化
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      if (to.meta.index > from.meta.index) {
        this.transitionName = 'fold-left';
      } else {
        this.transitionName = 'fold-right';
      }
    },
  }
}
</script>
<style>
</style>


import * as types from './mutation-type'
export default {
  // 保存登录信息
  [types.SET_USER_INFO](state, info) {
    state.userInfo = info
  },
  // 保存菜单跳转记录
  [types.SET_NAVGO_LIST](state, record) {
    const isFind = state.navGoList.find(item => item.pathName === record.name)
    state.navGoList.forEach(item => item.active = false)
    if (isFind) {
      isFind.active = true
    } else {
      const obj = {
        name: record.text,
        active: true,
        pathName: record.name,
        icon: record.icons,
        path: record.path
      }
      state.navGoList.push(obj)
    }
  },
  // tag跳转改变状态
  [types.HREF_NAV](state, hreInfo) {
    const isFind = state.navGoList.find(item => item.pathName === hreInfo.pathName)
    state.navGoList.forEach(item => item.active = false)
    isFind.active = true
  },
  // 删除指定跳转tag
  [types.DELETE_NAV_ITEM](state, detailInfo) {
    const isFind = state.navGoList.findIndex(tag => tag.pathName === detailInfo.pathName)
    if (isFind === -1) return
    state.navGoList.splice(isFind, 1)
  }
}
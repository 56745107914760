export default {
  // 用户信息
  userInfo: {},
  // menu跳转记录的列表
  navGoList: [
    {
      active: true,
      icon: "iconfont icon-yooxi",
      name: "会员",
      path: "memberInfo",
      pathName: "MemberInfo"
    }
  ]
}